import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HeroBanner from '../../../../theme/src/components/sections/hero-banner';
import Feature from '../../../../theme/src/components/sections/industry/feature-block';
import { TestimonialCard } from '../../../../theme/src/models/testimonial-card';
import TripleFeature from '../../../../theme/src/components/sections/triple-feature';
import ConnectWithUs from '../../../../theme/src/components/sections/connect-with-us';
import { Route } from '../../../../theme/src/models/site-links';
import WhyPatronscan from './why-patronscan';
import './id-scanner-for-bars.css';

const BarAndNightclubIndustry = () => {
  const data = useStaticQuery(graphql`
    query {
      fakeIDHero: file(relativePath: { eq: "hero-images/fakeID-hero-square.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      neverForgetsFace: file(relativePath: { eq: "entertainment/flagged_patrons.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIDAlert: file(relativePath: { eq: "entertainment/fakeID_alert.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KioskHome: file(relativePath: { eq: "entertainment/kioskHome.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      demographicReports: file(relativePath: { eq: "entertainment/demographic-reports-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      liveStatsTablet: file(relativePath: { eq: "entertainment/live_statistics.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      connectPhone: file(relativePath: { eq: "icons/readytoconnect.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      entrances: file(relativePath: { eq: "icons/entrances.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      handheldmembership: file(relativePath: { eq: "entertainment/handheld_Member.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      guestlist: file(relativePath: { eq: "icons/guestlists.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      questions: file(relativePath: { eq: "icons/questions.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstLogo: file(relativePath: { eq: "logosforbanner/bar-nightclub/Temple_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secondLogo: file(
        relativePath: { eq: "logosforbanner/bar-nightclub/cactus-club-cafe-logo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thirdLogo: file(relativePath: { eq: "logosforbanner/bar-nightclub/cowboys-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fourthLogo: file(relativePath: { eq: "logosforbanner/bar-nightclub/Boxcar-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contactTracing: file(relativePath: { eq: "feature-images/contact-trace.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const testimonials: TestimonialCard[] = [
    {
      testimonialVideo: 'https://player.vimeo.com/video/442852612',
      quote:
        '“On thanksgiving, a lot of people were out. We ran into four banned patrons for serious offences, sexual assaults, one fake ID and an underage [patron]. That was a night that it really worked out for us. Any one of those problems could have turned into a huge problem."',
      fullName: 'Daniel Alemar, Owner of Highwater',
    },
    {
      testimonialVideo: 'https://player.vimeo.com/video/442852614',
      quote:
        "I love that you get the demographics. They send you updates every hour once you're open... how many people in your club, how many men, how many women, when's your busiest time. I love that because it helps market your business.",
      fullName: 'Linda Allen, Owner of Lambda Cabaret',
    },
    {
      testimonialVideo: 'https://player.vimeo.com/video/442852611',
      quote:
        '“When Patronscan first came in it was a breath of fresh air. For what it can do, it was far superior than what we were currently using.”',
      fullName: 'Vincent May, Head of Security, Cowboys',
    },
    {
      testimonialVideo: 'https://player.vimeo.com/video/442852613',
      quote:
        '“As a police professional, Patronscan was definitely a net positive to our venues. The customer service I received from all of the people on the staff has been very professional."',
      fullName: 'Retired Sgt. Christopher Curtis, LVPD',
    },
  ];

  return (
    <div>
      <HeroBanner
        heroImage={data.fakeIDHero.childImageSharp.fluid}
        heading="Your Front Line"
        subHeading="Trusted ID scanners to protect your patrons, license and profit."
        videoLink="https://player.vimeo.com/video/366101059?autoplay=1&loop=1&autopause=0&muted=1"
        showVideo
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="UKIdScannerForBarsHero"
      />
      <Feature
        rightAlign
        image={data.KioskHome.childImageSharp.fluid}
        heading="The bouncer that"
        headingThin="never forgets a face"
        subHeading="Spot trouble from over 50,000 individuals known for assaults, chargebacks, drugs or vandalism."
        description="Reduce nightlife incidents by as much as 97% by spotting trouble before it becomes a problem. Receive alerts when troublemakers scan their ID including details on why they've been flagged."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="UKIdScannerForBarsFeature:BouncerThatNeverForgets"
      />
      <Feature
        image={data.fakeIDAlert.childImageSharp.fluid}
        heading="Accurate and reliable"
        headingThin="fake ID detection"
        subHeading="Spot fake IDs with algorithms built over 15 years of authenticating millions of IDs. "
        description="Patronscan analyzes over 6,000 data points to determine an ID's authenticity. Verify over 4,800 IDs from around the world, including all driver's licenses and passports with near-zero false positives."
        linkText="Get Pricing"
        link={Route.GetPricing}
        buttonId="UKIdScannerForBarsFeature:AccurateAndReliable"
      />
      <Feature
        rightAlign
        image={data.liveStatsTablet.childImageSharp.fluid}
        heading="Get peace of mind"
        headingThin="for pennies an hour"
        subHeading="Receive security alerts when flagged patrons, inspectors or minors arrive at the door."
        description="Keep a pulse on your venue with live stats sent to your phone. Access patron history to flag individuals, review your demographics and register members."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="UKIdScannerForBarsFeature:PeaceOfMind"
      />
      <Feature
        image={data.contactTracing.childImageSharp.fluid}
        heading="Log patron visits"
        headingThin="for contact tracing"
        subHeading="Anticipate contact tracing using Patronscan's built-in guest registry."
        description="Collect contact information quickly and timestamp patron visits. Securely access and export this information should contact tracing be required for potential COVID-19 exposure."
        linkText="Get Pricing"
        link={Route.GetPricing}
        buttonId="UKIdScannerForBarsFeature:LogPatronVists"
      />
      <Feature
        rightAlign
        image={data.handheldmembership.childImageSharp.fluid}
        heading="Manage memberships"
        headingThin="and treat VIPs"
        subHeading="Deliver memorable experiences by sending staff notifications when VIPs arrive."
        description="Handle private club membership requirements and reward big spenders with special treatment. Tier your members into groups so your door staff recognize and welcome your most valued patrons."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="UKIdScannerForBarsFeature:ManageMemberships"
      />
      <Feature
        image={data.demographicReports.childImageSharp.fluid}
        heading="Valuable insights"
        headingThin="into your business"
        subHeading="Receive daily, weekly and monthly reports on performance and demographics."
        description="Spot trends and build relationships with your most valuable patrons. Boost revenue by tailoring your marketing efforts to demographics most likely to spend more."
        linkText="Get Pricing"
        link={Route.GetPricing}
        buttonId="UKIdScannerForBarsFeature:ValuableInsights"
      />
      <TripleFeature
        firstFeatureIcon={data.entrances.childImageSharp.fluid}
        secondFeatureIcon={data.guestlist.childImageSharp.fluid}
        thirdFeatureIcon={data.questions.childImageSharp.fluid}
        firstHeadingText="Secure Multiple Entrances"
        secondHeadingText="Manage Guestlists"
        thirdHeadingText="Ask Challenge Questions"
        firstDescriptionText="Patronscan ID scanners sync in real-time at each of your entrances. It's the only surefire way to prevent ID passing."
        secondDescriptionText="Add patrons directly to your guestlist using the Patronscan mobile app. See how many patrons showed up to measure your promotional efforts."
        thirdDescriptionText="If you suspect a patron is using someone else’s ID, ask them a set of generated challenge questions from the scanner."
      />
      <WhyPatronscan />
      <div className="mt-20">
        <ConnectWithUs buttonId="UKIdScannerForBars" promptText="Request pricing from one of our experts or book a live demo to see Patronscan's ID scanners in action." />
      </div>
    </div>
  );
};

export default BarAndNightclubIndustry;
