import React, { useEffect, useState, FC, createRef } from 'react';
import axios from 'axios';
import CountUp from 'react-countup';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import './why-patronscan.css';

interface PatronscanStats {
  previous: {
    totalScans: number;
    totalFakes: number;
    totalCities: number;
  };
  current: {
    totalScans: number;
    totalFakes: number;
    totalCities: number;
  };
}

interface WhyPatronscanStats {
  backgroundColor?: string;
  buttonText?: string;
  link?: string;
  externalLink?: boolean;
}

const WhyPatronscan: FC<WhyPatronscanStats> = (props) => {
  let stats: PatronscanStats;
  const [duration, setDuration] = useState(2);
  const [totalScans, setTotalScans] = useState(0);
  const [totalFakes, setTotalFakes] = useState(0);
  const [totalCities, setTotalCities] = useState(0);
  const [previousScans, setPreviousScans] = useState(0);
  const [previousFakes, setPreviousFakes] = useState(0);
  const [previousCities, setPreviousCities] = useState(0);

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "stat-map.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const sectionRef = createRef<HTMLElement>();

  const updateStats = async () => {
    const statRequest = await axios.get('https://stats.patronscan.com/website-count/');
    stats = statRequest.data;
  };
  const updateCurrent = () => {
    setTotalScans(stats.current.totalScans);
    setTotalFakes(stats.current.totalFakes);
    setTotalCities(stats.current.totalCities);
  };
  const updatePrevious = () => {
    setPreviousScans(stats.previous.totalScans);
    setPreviousFakes(stats.previous.totalFakes);
    setPreviousCities(stats.previous.totalCities);
  };

  useEffect(() => {
    const io = new IntersectionObserver(async () => {
      await updateStats();
      updateCurrent();
      setInterval(async () => {
        setDuration(10);
        await updateStats();
        updatePrevious();
        updateCurrent();
      }, 10000);
    });
    io.observe(sectionRef.current);
  }, []);

  const formatNumber = (number: number) => {
    return number.toLocaleString();
  };

  return (
    <section
      ref={sectionRef}
      id="why"
      style={{ backgroundColor: props.backgroundColor ? props.backgroundColor : '#fff' }}
    >
      <div className="flex flex-row items-center flex-wrap why-patronscan-container">
        <div>
          <div className="why-container">
            <div className="why-patronscan-text-container">
              <h2 className="text-3xl text-black font-section-headers why-patronscan-heading mb-8 text-5xl">
                Why <span className="font-heading">Patronscan</span>?
              </h2>
              <p className="text-base why-sub-text">
                Because we are driven by continuous innovation, hyper customer care and precision 
                ID authentication technology to fight identity fraud on UK's best ID scanning network. 
              </p>
            </div>
            <div className="w-full flex pt-12 md:pt-0">
              <BackgroundImage
                className="bg-contain w-full"
                fluid={data.file.childImageSharp.fluid}
              >
                <div className="why-stats-container">
                  <h4 className="text-grey-darker mb-0 text-xl">Ids Scanned</h4>
                  <span className="text-5xl lg:text-6xl text-black m-0 leading-tight tracking-widest font-medium font-heading w-full block text-blue-darkest">
                    <CountUp
                      duration={duration}
                      start={previousScans}
                      formattingFn={formatNumber}
                      decimal=","
                      end={totalScans}
                    />
                  </span>
                  <h4 className="text-grey-darker mb-0 text-xl">Fakes Detected</h4>
                  <span className="text-5xl lg:text-6xl text-black m-0 leading-tight tracking-widest font-medium font-heading w-full block text-blue-darkest">
                    <CountUp
                      duration={duration}
                      start={previousFakes}
                      formattingFn={formatNumber}
                      decimal=","
                      end={totalFakes}
                    />
                  </span>
                  <h4 className="text-grey-darker mb-0 text-xl">Cities</h4>
                  <span className="text-5xl lg:text-6xl text-black m-0 leading-tight tracking-widest font-medium font-heading w-full block text-blue-darkest">
                    <CountUp
                      duration={duration}
                      start={previousCities}
                      formattingFn={formatNumber}
                      decimal=","
                      end={totalCities}
                    />
                  </span>
                </div>
              </BackgroundImage>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyPatronscan;
